<template>
  <div class="c-json-line">
    <p :class="`c-json-p c-line-${lineType}`"
       :style="getIndent(level)"
       @click="setVisiable">
      <span class="c-json-mark">{{ line }}</span>
      <span :class="`c-of-${lineType}`"></span>
      <span class="c-json-content">
        <span v-if="showIndex"
              class="c-json-key">{{ name }}: </span>
        <span class="c-json-pt">{{ isArray(type) ? '[' : '{' }}</span>
      </span>
      <span v-show="!visiable"
            class="c-json-pt">{{ isArray(type) ? '...]' : '...}' }}{{ needComma ? ',' : '' }}</span>
    </p>
    <div :style="{ display: visiable ? 'block' : 'none' }">
      <slot v-for="(item, index) in value">
        <Component
          :is="needFormat(item.type) ? 'self' : 'NormalTree'"
          :key="index"
          :level="level + 1"
          :name="item.name"
          :value="item.value"
          :type="item.type"
          :line="item.line"
          :show-index="item.showIndex"
          :need-comma="item.needComma"
          :line-type="item.lineType"
          :last-line-type="item.lastLineType"
          :last-line="item.lastLine" />
      </slot>
      <p class="c-json-feet"
         :class="`c-json-p c-line-${lineType}`"
         :style="getIndent(level)">
        <span v-if="lastLine"
              class="c-json-mark">{{ lastLine }}</span>
        <span v-if="lastLineType"
              :class="`c-of-${lastLineType}`"></span>
        <span class="c-json-pt">{{ isArray(type) ? ']' : '}' }}{{ needComma ? ',' : '' }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  import {getIndent, getType, isArray, needFormat} from './utils';
  import NormalTree from './normalTree';

  export default {
    name: 'self',
    components: {
      NormalTree
    },
    props: {
      name: {
        type: [String, Number],
        default: ''
      },
      value: {
        type: [Array, String],
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      line: {
        type: Number,
        default: 1
      },
      showIndex: Boolean,
      needComma: Boolean,
      level: {
        type: Number,
        default: 1
      },
      lineType: {
        type: String,
        default: ''
      },
      lastLineType: {
        type: String,
        default: ''
      },
      lastLine: {
        type: [String, Object, Number],
        default: null
      }
    },
    data() {
      return {
        visiable: true
      };
    },
    methods: {
      setVisiable() {
        this.visiable = !this.visiable;
      },
      isArray: isArray,
      getIndent: getIndent,
      getType: getType,
      needFormat: needFormat
    }
  };
</script>
