(<template>
  <article :class="{'in-progress': progressActive,
                    'system-log-item--notification': isNotificationLog}"
           class="system-log-item is-progress-bar">
    <p class="system-log-item__date"
       v-html="logDate"></p>
    <div v-if="isNotificationLog"
         class="system-log-item__image system-log-item__image--notification"></div>
    <div v-else-if="logActor.showAvatar"
         class="system-log-item__image-wrapper">
      <tt-avatar :avatar="logActor.avatar"
                 class="system-log-item__image" />
      <span v-if="logActor.role">{{ logActor.role }}</span>
    </div>
    <div class="system-log-item__text-wrapper">
      <log-text :log="log" />
      <vue-json-compare v-if="showLogDetails"
                        :old-data="logDetailsOldVersion"
                        :new-data="logDetailsNewVersion"
                        class="system-log-item__json" />
      <button class="sk-link sk-link--default"
              @click="clickShowDetails">{{ showLogDetailsText }}</button>
    </div>
  </article>
</template>)

<script>
  import {mapActions, mapMutations} from 'vuex';
  import VueJsonCompare from '@/vue-json-compare';
  import LogText from '@/components/assignment_components/one_assignment/system_log/LogText';

  export default {
    components: {
      'log-text': LogText,
      'vue-json-compare': VueJsonCompare,
    },
    props: {
      log: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      // action logs
      actor() { return this.log.actor || {}; },
      adminActor() { return this.log.adminActor || {}; },
      logActor() {
        switch (this.actorRole) {
          case 'admin':
            return {...this.adminActor, showAvatar: true, role: this.$gettext('Admin')};
          case 'demander':
            return {...this.actor, showAvatar: true, role: this.$gettext('Demander')};
          case 'supplier':
            return {...this.actor, showAvatar: true, role: this.$gettext('Supplier')};
          case 'system':
            return {name: this.$gettext('System'), showAvatar: false};
          case 'unknown':
            return {name: this.$gettext('Unknown'), showAvatar: false};
          default:
            return {};
        }
      },

      // both
      showLogDetails() { return this.log.details !== undefined && this.log.showDetails; },
      showLogDetailsText() {
        return this.log.showDetails ? this.$gettext('Hide details') : this.$gettext('See more details');
      },
      actorRole() { return this.log.actorRole || ''; },
      isNotificationLog() { return this.log.loggedActionType === 'job_notification'; },
      logDate() {
        const momentDate = this.$moment(this.log.createdAt);
        return `${momentDate.format('DD.MM.YYYY')}<br/>${momentDate.format('HH:mm')}`;
      },
      logDetails() {
        return this.log.details || {};
      },
      logDetailsNewVersion() {
        return this.logDetails.new_version || this.logDetails;
      },
      logDetailsOldVersion() {
        return this.logDetails.old_version || this.logDetails;
      }
    },
    methods: {
      ...mapActions('SystemLogStore', ['fetchSystemLogDetails']),
      ...mapMutations('SystemLogStore', ['setShowingDetails']),
      clickShowDetails() {
        const logId = this.log.id;

        if (this.log.details !== undefined) this.setShowingDetails({logId, value: !this.log.showDetails});
        else this.getLogDetails(logId);
      },
      getLogDetails(logId) {
        this.progressActive = true;
        this.fetchSystemLogDetails(logId).then(() => {
          this.setShowingDetails({logId, value: true});
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>

<style scoped>
  .system-log-item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    margin-left: 30px;
    padding: 20px 15px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .system-log-item--notification {
    background-color: #cce6e7;
  }

  .system-log-item__date {
    position: absolute;
    top: 50%;
    left: -50px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    transform: translate(-100%, -50%);
  }

  .system-log-item__date::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -22.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff5b24;
    background-color: var(--color-secondary);
    box-shadow: 0 0 0 5px #00cc774d;
    transform: translateY(-50%);
  }

  .system-log-item__image {
    width: 30px;
    height: 30px;
    margin-bottom: 3px;
  }

  .system-log-item__image--notification {
    background-image: url(~@assets/imgs/undefined_imgs/notifications.svg);
    background-repeat: no-repeat;
  }

  .system-log-item__text-wrapper {
    overflow: auto;
    width: 100%;
    padding-left: 10px;
  }

  .system-log-item__json {
    margin: 10px 0;
    word-break: break-all;
  }

  .system-log-item__image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
  }

  .system-log-item__details {
    overflow: auto;
    white-space: pre;
  }
</style>
