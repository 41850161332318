(<template>
  <article class="system-log">
    <template v-if="logsList.length">
      <div class="system-log__logs-wrapper">
        <system-log-item v-for="log in logsList"
                         :key="log.id"
                         :log="log" />
      </div>
      <sk-pagination v-if="pageTotal && pageTotal > 1"
                     :current="+currentPage"
                     :total="+pageTotal"
                     class="system-log__pagination"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </template>
    <div v-else
         class="system-log__no-logs">{{ noLogsText }}</div>
  </article>
</template>)

<script>
  import {mapGetters, mapMutations} from 'vuex';
  import SystemLogItem from '@/components/assignment_components/one_assignment/system_log/SystemLogItem';

  export default {
    asyncData({store, route, fetcher}) {
      const jobId = route.params.id;
      const orderId = route.params.id;
      const isOnTranslation = route.query.isOnTranslation;
      const token = route.query.token;
      const {page = 1} = route.query;
      let promisesArr = '';

      if (token && fetcher) {
        fetcher.setToken(token);
        store.commit('TTAuthStore/setGodMode', true);
      }
      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'language')];
      }
      if (!store.state.SystemLogStore.logs.logs && page && (store.state.TTAuthStore.godmode || token)) {
        if (isOnTranslation == 'true') {
          promisesArr = [...promisesArr, store.dispatch('SystemLogStore/fetchProjectSystemLogsList', {orderId, params: route.query})];
        } else {
          promisesArr = [...promisesArr, store.dispatch('SystemLogStore/fetchSystemLogsList', {jobId, params: route.query})];
        }
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'system-log-item': SystemLogItem
    },
    data() {
      return {
        currentPage: +this.$route.query.page || 1,
        token: this.$route.query.token
      };
    },
    computed: {
      ...mapGetters('SystemLogStore', [
        'logsList',
        'pageTotal'
      ]),
      noLogsText() { return this.$gettext('This assignment was created before the system log feature was launched.'); }
    },
    watch: {
      $route(newValue) {
        // reset godmode on route change other than token system log access
        if (newValue.name !== 'SystemLog') this.resetGodmode();
      }
    },
    methods: {
      ...mapMutations('SystemLogStore', ['clearStore']),
      submitFilters() {
        const params = {};
        if (this.currentPage) {
          params.page = this.currentPage;
        }
        if (this.token) {
          params.token = this.token;
        }
        params.isOnTranslation = this.$route.query.isOnTranslation || false;
        this.clearStore();
        this.$router.push(this.$makeRoute({name: 'SystemLog', params: {id: this.$route.params.id}, query: params}));
      },
      toPrevPage() {
        this.currentPage -= 1;
        this.submitFilters();
      },
      toNewPage(page) {
        this.currentPage = +page;
        this.submitFilters();
      },
      toNextPage() {
        this.currentPage += 1;
        this.submitFilters();
      },
      resetGodmode() {
        // if logged out system log access reset godmode state
        if (!this.$store.state.TTAuthStore.loggedIn) {
          this.$store.commit('TTAuthStore/setGodMode', false);
        }
      }
    },
    beforeDestroy() {
      this.resetGodmode();
    },
    beforeRouteLeave(to, from, next) {
      this.resetGodmode();
      this.clearStore();
      next();
    },
  };
</script>

<style>
  .system-log__pagination .sk-pagination__mobile-wrapper {
    margin: 0 0 0 -65px;
  }
</style>

<style scoped>
  .system-log {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 30px 20px 30px 85px;
    background-color: #e9ebef;
  }

  .system-log__logs-wrapper {
    position: relative;
    width: 100%;
    padding: 20px 0;
    border-left: 5px solid #d3d5de;
  }

  .system-log__logs-wrapper::before,
  .system-log__logs-wrapper::after {
    content: '';
    position: absolute;
    left: -2.5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ff5b24;
    background-color: var(--color-secondary);
    transform: translate(-50%, -50%);
  }

  .system-log__pagination {
    padding-top: 10px;
  }

  .system-log__logs-wrapper::before {
    top: 0;
    transform: translate(-50%, -50%);
  }

  .system-log__logs-wrapper::after {
    bottom: 0;
    transform: translate(-50%, 50%);
  }

  .system-log__no-logs {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-right: 65px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
</style>
