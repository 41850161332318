<template>
  <div class="c-json-view">
    <p class="c-json-outter">{{ isArray(newData) ? '[' : '{' }}</p>
    <Tree v-for="(item, index) in mergeView"
          :key="index"
          :name="item.name"
          :value="item.value"
          :type="item.type"
          :line="item.line"
          :show-index="item.showIndex"
          :need-comma="item.needComma"
          :line-type="item.lineType"
          :last-line-type="item.lastLineType"
          :last-line="item.lastLine" />
    <p class="c-json-outter">{{ isArray(newData) ? ']' : '}' }}</p>
  </div>
</template>

<script>
  import {isArray, mergeData} from './utils';
  import Tree from './tree';

  export default {
    components: {
      Tree
    },
    props: {
      oldData: {
        type: [Object, undefined],
        default() {
          return {};
        }
      },
      newData: {
        type: [Object, undefined],
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        mergeView: []
      };
    },
    watch: {
      oldData() {
        this.updateView();
      },
      newData() {
        this.updateView();
      }
    },
    methods: {
      isArray: isArray,
      updateView() {
        this.mergeView = mergeData(this.oldData, this.newData);
      }
    },
    mounted() {
      this.updateView();
    }
  };
</script>

<style>
  .c-json-view {
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 10px 10px 40px;
    background-color: #fbfbfb;
    word-wrap: break-word;
  }

  .c-json-line,
  .c-json-p,
  .c-json-feet {
    position: relative;
    margin: 0;
    text-align: left;
  }

  .c-json-line {
    padding: 0;
  }

  .c-line-del {
    background-color: #ffeef0;
  }

  .c-line-add {
    background-color: #e6ffed;
  }

  .c-of-del::after {
    content: '-';
    position: absolute;
    top: 3px;
    left: 5px;
    z-index: 5;
    width: 10px;
    height: 15px;
    color: #ff6f6f;
    text-indent: 0;
  }

  .c-of-add::after {
    content: '+';
    position: absolute;
    top: 3px;
    left: 5px;
    z-index: 5;
    width: 10px;
    height: 15px;
    color: #3eb93e;
    text-indent: 0;
  }

  .c-json-p {
    cursor: pointer;
  }

  .c-json-p:hover {
    background: #f6f6f6;
  }

  .c-json-outter {
    transform: translateX(-35px);
  }

  .c-json-mark {
    position: absolute;
    top: 2px;
    left: -30px;
    z-index: 5;
    color: #545454;
    font-size: 12px;
    text-indent: 0;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .c-json-content {
    font-size: 14px;
  }

  .c-json-content .c-json-key {
    color: #5cadff;
    font-size: 15px;
  }

  .c-json-items {
    color: #b1b1b1;
  }

  .c-json-comma {
    color: #5cadff;
  }

  .c-json-pt {
    color: #5cadff;
  }

  .c-json-number {
    color: #ae81ff;
  }

  .c-json-string {
    color: #22863a;
  }

  .c-json-null,
  .c-json-boolean,
  .c-json-undefined,
  .c-json-regexp,
  .c-json-date,
  .c-json-set,
  .c-json-map,
  .c-json-error,
  .c-json-symbol,
  .c-json-function {
    padding: 0 2px;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: #f7f7f7;
  }

  .c-json-boolean {
    color: #6f73ff;
  }

  .c-json-null {
    color: #66d9ef;
  }

  .c-json-undefined {
    color: #f92672;
  }

  .c-json-date {
    color: #09d3ac;
  }

  .c-json-regexp {
    color: #2ea021;
  }

  .c-json-function {
    color: #a2af3d;
  }

  .c-json-symbol {
    color: #fd3db8;
  }

  .c-json-error {
    color: #afafaf;
  }

  .c-json-set,
  .c-json-map {
    color: #f56847;
  }
</style>
