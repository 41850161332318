<template>
  <p :class="`c-json-p c-line-${lineType}`"
     :style="getIndent(level)">
    <span class="c-json-mark">{{ line }}</span>
    <span :class="`c-of-${lineType}`"></span>
    <span class="c-json-content">
      <span v-if="showIndex"
            class="c-json-key">{{ name }}: </span>
      <span :class="`c-json-${type}`">{{ value }}</span>
      <span class="c-json-comma">{{ needComma ? ',' : '' }}</span>
    </span>
  </p>
</template>

<script>
  import {getIndent} from './utils';

  export default {
    props: {
      name: {
        type: [String, Number],
        default: ''
      },
      value: {
        type: [Array, String],
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      line: {
        type: Number,
        default: 1
      },
      showIndex: Boolean,
      needComma: Boolean,
      level: {
        type: Number,
        default: 1
      },
      lineType: {
        type: String,
        default: ''
      },
      lastLineType: {
        type: String,
        default: ''
      },
      lastLine: {
        type: [String, Object, Number],
        default: null
      }
    },
    methods: {
      getIndent: getIndent
    }
  };
</script>
