<template>
  <Component
    :is="needFormat(type) ? 'ComplexTree' : 'NormalTree'"
    :name="name"
    :value="value"
    :type="type"
    :line="line"
    :show-index="showIndex"
    :need-comma="needComma"
    :level="level"
    :line-type="lineType"
    :last-line-type="lastLineType"
    :last-line="lastLine" />
</template>

<script>
  import {needFormat} from './utils';
  import ComplexTree from './complexTree';
  import NormalTree from './normalTree';

  export default {
    components: {
      ComplexTree,
      NormalTree
    },
    props: {
      name: {
        type: [String, Number],
        default: ''
      },
      value: {
        type: [Array, String],
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      line: {
        type: Number,
        default: 1
      },
      showIndex: Boolean,
      needComma: Boolean,
      level: {
        type: Number,
        default: 1
      },
      lineType: {
        type: String,
        default: ''
      },
      lastLineType: {
        type: String,
        default: ''
      },
      lastLine: {
        type: [String, Object, Number],
        default: null
      }
    },
    methods: {
      needFormat: needFormat
    }
  };
</script>
